.cs-candidate-referral-cancel {
    color: white!important;
    background: #0B7DBB!important;
    font-weight: bold;
}

.cs-candidate-referral-nav {
    background: #0FBEDB!important;
    margin-left: 0!important;
    margin-right: 0!important;
}

.cs-candidate-referral-input-text {
    margin-left: 2em!important;
    margin-top: -1em!important;
    margin-bottom: -1em!important;
}

.cs-candidate-referral-helper {
    margin-left: 1em!important;
    margin-right: 1em!important;
}

.cs-candidate-referral-textarea .mbsc-textarea {
    height: 15em!important;
}

.cs-candidate-referral-group-title {
    border-bottom-color: #ccc;
    border-bottom-width: thin;
    border-bottom-style: solid;
}

.cs-candidate-referral-group-title-text {
    margin-bottom: 0!important;
}
