.cs-dashboard-header-logo {
  margin-left: 2em;
  margin-right: .5em;
  background-image: url("../images/LogoShort.png")!important;
  width: 8em;
  height: 2.25em;
  margin-top: 5px;
  background-size: contain;
  background-repeat: no-repeat;
}

.cs-dashboard-header-toolbar {
  position: fixed;
  background: white;
  z-index: 9999;
  border-bottom-color: #ccc;
  border-bottom-width: thin;
  border-bottom-style: solid;
}

.cs-dashboard-header-col-xs {
  padding: 0!important;
}

.cs-dashboard-header-button-icon {
  height: 1.5em!important;
  color: #0B7DBB!important;
}

.cs-dashboard-header-button-icon-disabled {
  height: 1.5em!important;
  color: #7c7c7c!important;
}

.cs-dashboard-header-button-cursor {
  cursor: default;
}

.cs-dashboard-header-button-icon-pipe {
  padding-top: .5em!important;
  height: 1.5em!important;
}

.cs-dashboard-header-avatar {
  width: 2em;
  height: 2em;
  border-radius: 1em;
  background-size: contain;
  background-repeat: no-repeat;
  margin-top: .75em;
  margin-left: .5em;
  margin-right: 2em;
}

.cs-dashboard-header-text {
  margin-top: .25em!important;
  margin-bottom: -.25em!important;
  padding-left: .75em!important;
}
