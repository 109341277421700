.cs-partners-partner-card {
    background: #efeff4!important;
}

.cs-partners-partner-text {
    text-align: center;
}

.cs-partners-partner-text-p {
    margin: 0!important;
}

.cs-partners-partner-cover {
    background-size: cover;
    background-position: center;
    max-height: 200px;
    width: 100%;
    height: 300px;
    overflow: hidden;
}
