.cs-dashboard-header-discount-popup .mbsc-popup-padding {
  padding: 0;
}

.cs-dashboard-header-discount-helper {
  margin-right: 1em;
  margin-left: 1em;
}

.cs-dashboard-header-discount-textarea .mbsc-textarea {
  height: 9em!important;
}

.cs-dashboard-header-discount-cancel {
  color: white!important;
  background: #0B7DBB!important;
  font-weight: bold;
}

.cs-dashboard-header-discount-save {
  color: white!important;
  background: #0B7DBB!important;
  font-weight: bold;
}

.cs-dashboard-header-discount-text .mbsc-textarea {
  height: 15em;
}
