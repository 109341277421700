.cs-dashboard-header-menu-popup .mbsc-popup-padding {
  padding: 0!important;
}

.cs-dashboard-header-menu-icon-secondary {
  height: 1.5em!important;
  color: #12B1B9;
  margin-right: 1em!important;
  min-width: 1.75em!important;
}

.cs-dashboard-header-menu-icon-secondary-disabled {
  height: 1.5em!important;
  color: #7c7c7c;
  margin-right: 1em!important;
  min-width: 1.75em!important;
}

.cs-dashboard-header-menu-icon {
  color: #0B7DBB!important;
}

.cs-dashboard-header-menu-icon-danger {
  height: 1.5em!important;
  color: #f5504e;
  margin-right: 1em!important;
  min-width: 1.75em!important;
}

.cs-dashboard-header-menu-li {
  padding: .5em!important;
}
