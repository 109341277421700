.cs-login-referral-popup .mbsc-popup-padding {
  padding: 0;
}

.cs-login-referral-helper {
  margin-right: 1em;
  margin-left: 1em;
}

.cs-login-referral-textarea .mbsc-textarea {
  height: 9em!important;
}

.cs-login-referral-cancel {
  color: white!important;
  background: #0B7DBB!important;
  font-weight: bold;
}

.cs-login-referral-save {
  color: white!important;
  background: #0B7DBB!important;
  font-weight: bold;
}
