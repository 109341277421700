.cs-users-user-popup .mbsc-popup-padding {
    padding: 0;
}

.cs-users-user-save {
    color: white!important;
    background: #0B7DBB!important;
    font-weight: bold;
}

.cs-users-user-cancel {
    color: white!important;
    background: #0B7DBB!important;
    font-weight: bold;
}

.cs-users-user-avatar {
    width: 5em;
    height: 5em;
    border-radius: 2.5em;
    background-size: contain;
    background-repeat: no-repeat;
    margin-bottom: 1.25em!important;
}

.cs-users-user-checkbox {
    padding-left: 3.75em!important;
    color: #0B7DBB!important;
}

.cs-users-user-helper {
    margin-right: 1em;
    margin-left: 1em;
}
