.cs-login-register-popup .mbsc-popup-padding {
  padding: 0;
}

.cs-login-register-helper {
  margin-right: 1em;
  margin-left: 1em;
}

.cs-login-register-nav {
  background: #0FBEDB!important;
  border-radius: 0!important;
  margin: 0 0 0.1em !important;
}

.cs-login-register-group-title-text {
  margin-bottom: 0.25em!important;
}

.cs-login-register-cancel {
  color: white!important;
  background: #0B7DBB!important;
  font-weight: bold;
}

.cs-login-register-next {
  color: white!important;
  background: #0B7DBB!important;
  font-weight: bold;
}

.cs-login-register-checkbox {
  padding-left: 3.75em!important;
  color: #0B7DBB!important;
}
