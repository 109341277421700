.cs-dashboard-header-membership-popup .mbsc-popup-padding {
    padding: 0;
}

.cs-dashboard-header-membership-save {
    color: white!important;
    background: #0B7DBB!important;
    font-weight: bold;
}

.cs-dashboard-header-membership-helper {
    margin-right: 1em;
    margin-left: 1em;
}

.cs-dashboard-header-membership-title {
    margin-bottom: 0.25em!important;
}

.cs-dashboard-header-membership-links {
    color: #0FBEDB!important;
    font-weight: 600!important;
}
