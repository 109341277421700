.cs-dashboard-title p {
    color: whitesmoke;
    margin-bottom: 0;
}

.cs-dashboard-right {
    padding-left: 0;
}

.cs-dashboard-left {
    padding-right: 0;
}

.cs-dashboard-directory {
    padding: 0!important;
}

#cs-dashboard-card-menu {
    margin-bottom: 0;
}

.ck-toolbar {
    min-height: 41px;
}

.cs-dashboard-notes-li {
    background: transparent!important;
}

.cs-dashboard-notes-li .mbsc-lv {
    background: transparent!important;
}

.cs-dashboard-notes-li .mbsc-lv-item {
    padding: 0.1em!important;
}

.cs-dashboard-popup-delete {
    color: rgb(228, 3, 3);
}