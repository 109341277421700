.cs-job-new-main {
    background: white;
    margin-right: 0!important;
    margin-left: 0!important;
}

.cs-job-new-footer {
    background: white;
    margin-right: 0!important;
    margin-left: 0!important;
    border-top-color: #ccc;
    border-top-width: thin;
    border-top-style: solid;
    padding-left: .5em;
}

.cs-job-new-icon-upload {
    height: 1.5em!important;
    margin-right: .3em!important;
    margin-top: -.25em!important;
    margin-bottom: -.25em!important;
    margin-left: .5em!important;
}
