.cs-user-documents-icon-upload {
    height: 1.5em!important;
    margin-right: .3em!important;
    margin-top: -.25em!important;
    margin-bottom: -.25em!important;
    margin-left: .5em!important;
}

.cs-user-documents-icon-referral {
    height: 2.5em!important;
    margin-right: 1em!important;
    margin-top: 0!important;
    margin-bottom: 0!important;
    margin-left: .25em!important;
    color: #376e9f!important;
}

.cs-user-documents-list {
    margin-top: .5em;
    margin-left: 1em;
    margin-right: 0;
}

.cs-user-documents-file {
    margin-left: 1em;
}

.cs-user-documents-file-text {
    margin-top: 0!important;
    margin-bottom: .25em!important;
    margin-left: .75em!important;
}

.cs-user-documents-file-button {
    margin-right: .5em;
    margin-left: 0;
}

.cs-user-documents-main {
    background: white;
    margin-right: 0!important;
    margin-left: 0!important;
}

.cs-user-documents-badge-incomplete {
    height: 2em!important;
    color: #f5504e!important;
    margin-top: .75em;
    margin-right: .75em;
    margin-left: 1em;
}

.cs-user-documents-helper {
    margin-right: 1em;
    margin-left: 1em;
}

.cs-user-documents-header-title {
    margin-bottom: 0!important;
    margin-left: 1em!important;
}

.cs-user-documents-header-files {
    margin-bottom: 1em!important;
    margin-left: 1em!important;
}
