.cs-navigation-item-badge-incomplete {
    height: 1.50em!important;
    color: #f5504e!important;
    margin-left: .25em!important;
    margin-top: -.25em!important;
    margin-bottom: -.25em!important;
}

.cs-navigation-item-badge-complete {
    height: 1.50em!important;
    color: #0B7DBB!important;
    margin-left: .25em!important;
    margin-top: -.25em!important;
    margin-bottom: -.25em!important;
}
