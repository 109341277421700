.cs-user-spouse-icon .mbsc-avatar {
    height: 5em;
    width: 5em;
    border-radius: 2.5em;
    margin-top: 1em;
}

.cs-user-spouse-content {
    padding: 0!important;
}

.cs-user-spouse-checkbox {
    padding-left: 3.75em!important;
    color: #0B7DBB!important;
    background: white!important;
    margin-top: 1.5em!important;
    margin-right: 1em!important;
    margin-left: 1em!important;
    border-radius: .5em!important;
    border: 1px solid #ccc;
}

.cs-user-spouse-checkbox:before {
    border-top: 0 solid white!important;
    border-bottom: 0 solid white!important;
}

.cs-user-spouse-checkbox:after {
    border-bottom: 0 solid white!important;
    border-top: 0 solid white!important;
}

.cs-user-spouse-footer {
    background: white;
    margin-right: 0!important;
    margin-left: 0!important;
    border-top-color: #ccc;
    border-top-width: thin;
    border-top-style: solid;
}

.cs-user-spouse-footer-actions {
    margin-left: .5em!important;
}

.cs-user-spouse-footer-note {
    padding-top: 1em!important;
    padding-bottom: 1em!important;
    padding-right: 1em!important;
}
