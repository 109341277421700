.cs-job-requirements-col-ck {
    background: white;
}

.cs-job-requirements-col-ck .ck.ck-editor__top .ck-sticky-panel .ck-sticky-panel__content {
    border: none!important;
}

.cs-job-requirements-footer {
    background: white;
    margin-right: 0!important;
    margin-left: 0!important;
    border-top-color: #ccc;
    border-top-width: thin;
    border-top-style: solid;
}

.cs-job-requirements-footer-actions {
    padding-top: 1em!important;
    padding-bottom: 1em!important;
    padding-left: 1em!important;
}

.cs-job-requirements-footer-note {
    padding-top: 1em!important;
    padding-bottom: 1em!important;
    padding-right: 1em!important;
}
