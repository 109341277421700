.mbsc-ios.mbsc-input .mbsc-label {
    color: #6a7785 !important;
}

.mbsc-ios .mbsc-fr-btn {
    color: #0B7DBB!important;
}

.mbsc-popup-header {
    color: #0B7DBB!important;
}

.mbsc-calendar-header {
    background: white!important;
}

.mbsc-ios.mbsc-input .mbsc-label, .mbsc-ios.mbsc-progress .mbsc-label, .mbsc-ios.mbsc-input input, .mbsc-ios.mbsc-input textarea #cs-header-select {
    color: #6a7785!important;
    margin-top: 4px;
    font-weight: bold;
}

.mbsc-schedule-date-header {
    background: white!important;
}

.ck-toolbar {
    border: none !important;
}

.ck.ck-editor__main>.ck-editor__editable:not(.ck-focused) {
    border: none!important;
}
