.cs-school-notes-header {
    padding-left: 2.5em;
    padding-top: 1.5em;
}

.cs-school-notes-main {
    background: white;
    margin-right: 0!important;
    margin-left: 0!important;
}

.cs-school-notes-main-header {
    padding: 0!important;
}

.cs-school-notes-col-ck {
    background: white;
}

.cs-school-notes-col-ck .ck.ck-editor__top .ck-sticky-panel .ck-sticky-panel__content {
    border: none!important;
}

.cs-school-notes-footer {
    background: white;
    margin-right: 0!important;
    margin-left: 0!important;
    border-top-color: #ccc;
    border-top-width: thin;
    border-top-style: solid;
}

.cs-school-notes-footer-actions {
    padding-top: 1em!important;
    padding-bottom: 1em!important;
    padding-left: 1em!important;
}

.cs-school-notes-footer-note {
    padding-top: 1em!important;
    padding-right: 1em!important;
}

.cs-school-notes-header-title {
    margin-bottom: 0!important;
}

.cs-school-notes-avatar {
    width: 2.5em;
    height: 2.5em;
    border-radius: 1.25em;
    background-size: contain;
    background-repeat: no-repeat;
    margin-top: 1em;
    margin-left: 2em;
    margin-right: 2em;
    margin-bottom: 1em;
}
