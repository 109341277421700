.cs-school-about-icon-item {
    height: 1.5em!important;
    margin-right: .3em!important;
    margin-top: -.25em!important;
    margin-bottom: -.25em!important;
    margin-left: .5em!important;
}

.cs-school-about-col-ck {
    background: white;
}

.cs-school-about-col-ck .ck.ck-editor__top .ck-sticky-panel .ck-sticky-panel__content {
    border: none!important;
}

.cs-school-footer {
    background: white;
    margin-right: 0!important;
    margin-left: 0!important;
    border-top-color: #ccc;
    border-top-width: thin;
    border-top-style: solid;
}

.cs-school-footer-actions {
    padding-top: 1em!important;
    padding-bottom: 1em!important;
    padding-left: 1em!important;
}

.cs-school-footer-note {
    padding-top: 1em!important;
    padding-bottom: 1em!important;
    padding-right: 1em!important;
}
