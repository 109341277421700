.cs-job-main {
    padding-top: 3em;
}

.cs-job-section {
    background: white;
    margin-right: 0!important;
    margin-left: 0!important;
}

.cs-job-avatar {
    width: 2.5em;
    height: 2.5em;
    border-radius: 1.25em;
    background-size: contain;
    background-repeat: no-repeat;
    margin-top: 1em;
    margin-left: 2em;
    margin-right: 1.5em;
    margin-bottom: 1em;
}

.cs-job-header-title {
    margin-bottom: 0!important;
}

.cs-job-title p {
    color: #0FBEDB!important;
    margin-bottom: .5em!important;
}

.cs-job-right {
    margin-bottom: 1em!important;
    height: calc(100vh - 8.5em)!important;
    overflow-y: auto!important;
}

.cs-job-left {
    padding-right: 0!important;
}

.cs-job-container {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 1em;
    height: 4.5em;
}

.cs-job-container-xs {
    padding-left: 0;
    padding-top: .5em;
}

.cs-job-nav {
    background: #0FBEDB!important;
    margin-left: 0!important;
    margin-right: 0!important;
}
