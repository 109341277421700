.cs-school-faculty-ethnicity-item-name {
    margin-top: .5em;
    margin-left: 0;
}

.cs-school-faculty-ethnicity-item-text {
    padding-top: .5em;
}

.cs-school-faculty-ethnicity-item-buttons {
    margin-right: .5em;
    margin-left: 0;
}

.cs-school-faculty-ethnicity-popup .mbsc-popup-padding {
    padding: 0;
}

.cs-school-faculty-ethnicity-cancel {
    color: white!important;
    background: #0B7DBB!important;
    font-weight: bold;
}

.cs-school-faculty-ethnicity-button-add {
    margin-top: 1em!important;
    margin-bottom: 0!important;
}

.cs-school-faculty-ethnicity-helper {
    margin-right: 1em;
    margin-left: 1em;
}

.cs-school-faculty-ethnicity-icon {
    height: 1.5em!important;
    margin-right: 0!important;
    margin-left: 1em!important;
}

.cs-school-faculty-ethnicity-item-icon-left {
    height: 2em!important;
    margin-right: 1em!important;
    margin-top: 0!important;
    margin-bottom: 0!important;
    margin-left: 1.5em!important;
    color: #376e9f!important;
}
