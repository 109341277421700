.cs-jobs-favorites-helper {
    margin-right: 2em!important;
    margin-left: 1em!important;
    margin-top: 1.5em;
}

.cs-jobs-favorites-list {
    margin-top: 3px;
    padding-left: 1.5em;
    margin-right: 0;
    padding-top: 3px;
}

.cs-jobs-favorites-avatar {
    width: 2.5em;
    height: 2.5em;
    border-radius: 1.25em;
    background-size: contain;
    background-repeat: no-repeat;
    margin-top: .25em;
    margin-left: 0;
    margin-right: .5em;
}

.cs-jobs-favorites-job {
    margin-left: 1em;
}

.cs-jobs-favorites-icon {
    height: 1.25em!important;
    color: #0B7DBB!important;
    margin-left: 1em!important;
}

.cs-jobs-favorites-job-text {
    margin-top: 0!important;
    margin-bottom: .25em!important;
    margin-left: .75em!important;
}
