.cs-job-notes-main {
    background: white;
    margin-right: 0!important;
    margin-left: 0!important;
}

.cs-job-notes-col-ck {
    background: white;
}

.cs-job-notes-col-ck .ck.ck-editor__top .ck-sticky-panel .ck-sticky-panel__content {
    border: none!important;
}

.cs-job-notes-footer {
    background: white;
    margin-right: 0!important;
    margin-left: 0!important;
    border-top-color: #ccc;
    border-top-width: thin;
    border-top-style: solid;
}

.cs-job-notes-avatar {
    width: 2.5em;
    height: 2.5em;
    border-radius: 1.25em;
    background-size: contain;
    background-repeat: no-repeat;
    margin-top: 1em;
    margin-left: 2em;
    margin-right: 2em;
    margin-bottom: 1em;
}

.cs-job-notes-header-title {
    margin-bottom: 0!important;
}

.cs-job-notes-links {
    color: #0FBEDB!important;
    font-weight: 600!important;
    cursor: pointer;
}

.cs-job-notes-button-icon {
    height: 1.25em!important;
    color: #0B7DBB!important;
    margin-right: .5em!important;
}
