.cs-school-leadership-gender-item-name {
    margin-top: .5em;
    margin-left: 0;
}

.cs-school-leadership-gender-item-text {
    padding-top: .5em;
}

.cs-school-leadership-gender-item-buttons {
    margin-right: .5em;
    margin-left: 0;
}

.cs-school-leadership-gender-popup .mbsc-popup-padding {
    padding: 0;
}

.cs-school-leadership-gender-helper {
    margin-right: 1em;
    margin-left: 1em;
}

.cs-school-leadership-gender-cancel {
    color: white!important;
    background: #0B7DBB!important;
    font-weight: bold;
}

.cs-school-leadership-gender-icon {
    height: 1.5em!important;
    margin-right: 0!important;
    margin-left: 1em!important;
}

.cs-school-leadership-gender-button-add {
    margin-top: 1em!important;
    margin-bottom: 0!important;
}

.cs-school-leadership-gender-item-icon-left {
    height: 2em!important;
    margin-right: 1em!important;
    margin-top: 0!important;
    margin-bottom: 0!important;
    margin-left: 1.5em!important;
    color: #376e9f!important;
}
