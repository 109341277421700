.cs-school-diversity-main {
    background: white;
    margin-right: 0!important;
    margin-left: 0!important;
}

.cs-school-diversity-checkbox {
    padding-left: 3.75em!important;
    color: #0B7DBB!important;
    background: white!important;
    margin-top: 1em!important;
    margin-bottom: 1.5em!important;
    margin-right: 0!important;
    margin-left: 0!important;
    border-radius: .5em!important;
    border: 1px solid #ccc;
}

.cs-school-diversity-checkbox:before {
    border-top: 0 solid white!important;
    border-bottom: 0 solid white!important;
}

.cs-school-diversity-checkbox:after {
    border-bottom: 0 solid white!important;
    border-top: 0 solid white!important;
}

.cs-school-diversity-edit-field-right {
    margin-right: 1.5em;
    margin-top: -1.5em;
    text-align: right;
}

.cs-school-diversity-text {
    text-align: center;
}

.cs-school-diversity-card-text-p {
    margin: 0!important;
}

.cs-school-diversity-card {
    background: #efeff4 !important;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}

.cs-school-diversity-card-section {
    margin-bottom: 1em;
}

.cs-school-diversity-card-section-left {
    padding-right: 0.5em!important;
}

.cs-school-diversity-card-section-right {
    padding-left: 0.5em!important;
}
