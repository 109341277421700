.cs-user-options-icon .mbsc-avatar {
    height: 5em;
    width: 5em;
    border-radius: 2.5em;
    margin-top: 1em;
}

.cs-user-options-content {
    padding: 0!important;
}

.cs-user-options-main {
    background: white;
    margin-right: 0!important;
    margin-left: 0!important;
    padding-bottom: 2em!important;
}

.cs-user-options-checkbox {
    padding-left: 3.75em!important;
    color: #0B7DBB!important;
    background: white!important;
    margin-top: 1.5em!important;
    margin-right: 1em!important;
    margin-left: 1em!important;
    border-radius: .5em!important;
    border: 1px solid #ccc;
}

.cs-user-options-checkbox:before {
    border-top: 0 solid white!important;
    border-bottom: 0 solid white!important;
}

.cs-user-options-checkbox:after {
    border-bottom: 0 solid white!important;
    border-top: 0 solid white!important;
}
