.cs-user-referrals-icon-upload {
    height: 1.5em!important;
    margin-right: .3em!important;
    margin-top: -.25em!important;
    margin-bottom: -.25em!important;
    margin-left: .5em!important;
}

.cs-user-referrals-icon-referral {
    height: 2.5em!important;
    margin-right: 1em!important;
    margin-top: -.25em!important;
    margin-bottom: -.25em!important;
    margin-left: 0!important;
    color: #376e9f!important;
}

.cs-user-referrals-icon .mbsc-avatar {
    height: 5em;
    width: 5em;
    border-radius: 2.5em;
    margin-top: 1em;
}

.cs-user-referrals-content {
    padding: 0!important;
}

.cs-user-referrals-helper {
    margin-right: 1em;
    margin-left: 1em;
}

.cs-user-referrals-badge-incomplete {
    height: 2em!important;
    color: #f5504e!important;
    margin-top: .75em;
    margin-right: .75em;
    margin-left: 1em;
}

.cs-user-referrals-main {
    background: white;
    margin-right: 0!important;
    margin-left: 0!important;
}

.cs-user-referrals-footer {
    background: white;
    margin-right: 0!important;
    margin-left: 0!important;
    border-top-color: #ccc;
    border-top-width: thin;
    border-top-style: solid;
}

.cs-user-referrals-footer-actions {
    margin-left: .5em!important;
}

.cs-user-referrals-footer-note {
    padding-top: 1em!important;
    padding-right: 1em!important;
}

.cs-user-referrals-header-title {
    margin-left: 1em!important;
}

.cs-user-referrals-name {
    margin-top: .5em;
    margin-left: 1em;
}

.cs-user-referrals-detail {
    margin-right: 2.5em;
}

.cs-user-referrals-delete {
    margin-right: .5em;
}
