.cs-candidates-search-button-icon-send {
    height: 1.5em!important;
    margin-right: .3em!important;
    margin-top: -.25em!important;
    margin-bottom: -.25em!important;
}

.cs-candidates-search-field-input {
    padding: 0!important;
    margin: 0!important;
}

.cs-candidates-search-field-input:before {
    border-top: 0 solid white!important;
    border-bottom: 0 solid white!important;
}

.cs-candidates-search-field-input:after {
    border-bottom: 0 solid white!important;
    border-top: 0 solid white!important;
}

.cs-candidates-search-footer {
    background: white;
    border-top-color: #ccc;
    border-top-width: thin;
    border-top-style: solid;
    margin-right: 0!important;
    margin-left: 0!important;
}

.cs-candidates-search-footer-segmented {
    padding-left: 0!important;
    padding-right: 1.5em!important;
}

.cs-candidates-search-footer-filter {
    padding-left: 1em!important;
    padding-right: 0!important;
    margin-top: .1em!important;
}

.cs-candidates-search-footer-filter .mbsc-form-control-wrapper:before {
    border-top: 0 solid white!important;
    border-bottom: 0 solid white!important;
}

.cs-candidates-search-footer-filter .mbsc-form-control-wrapper:after {
    border-bottom: 0 solid white!important;
    border-top: 0 solid white!important;
}

.cs-candidates-search-footer-menu {
    background: #0FBEDB!important;
}
