.cs-dashboard-header-cancel-popup .mbsc-popup-padding {
    padding: 0;
}

.cs-dashboard-header-cancel-textarea .mbsc-textarea {
    height: 9em!important;
}

.cs-dashboard-header-cancel-helper {
    margin-right: 1em;
    margin-left: 1em;
}

.cs-dashboard-header-cancel-cancel {
    color: white!important;
    background: #0B7DBB!important;
    font-weight: bold;
}

.cs-dashboard-header-cancel-save {
    color: white!important;
    background: #0B7DBB!important;
    font-weight: bold;
}
