.cs-job-candidates-results-list {
    margin-top: 3px;
    padding-left: 1.5em;
    margin-right: 0;
    padding-top: 3px;
}

.cs-job-candidates-avatar {
    width: 2.5em;
    height: 2.5em;
    border-radius: 1.25em;
    background-size: contain;
    background-repeat: no-repeat;
    margin-top: .25em;
    margin-left: 0;
    margin-right: .5em;
}

.cs-job-candidates-results-file {
    margin-left: 1em;
}

.cs-job-candidates-results-file-text {
    margin-top: 0!important;
    margin-bottom: .25em!important;
    margin-left: .75em!important;
}

.cs-job-candidates-helper {
    margin-right: 2em!important;
    margin-left: 1em!important;
    margin-top: 1.5em;
}
