.cs-jobs-main {
    padding-top: 3em;
}

.cs-jobs-title p {
    color: #0FBEDB!important;
    margin-bottom: .5em!important;
}

.cs-jobs-right {
    margin-bottom: 1em!important;
    height: calc(100vh - 8.5em)!important;
    overflow-y: auto!important;
}

.cs-jobs-left {
    padding-right: 0!important;
}

.cs-jobs-container {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 1em;
    height: 4.5em;
}

.cs-jobs-nav {
    background: #0FBEDB!important;
    margin-left: 0!important;
    margin-right: 0!important;
}

.cs-jobs-section {
    background: white;
    margin-right: 0!important;
    margin-left: 0!important;
}

.cs-jobs-container-xs {
    padding-left: 0;
    padding-top: .5em;
}
