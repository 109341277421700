.cs-notes-li {
  background: transparent!important;
}

.cs-notes-li .mbsc-lv {
  background: transparent!important;
}

.cs-notes-li .mbsc-lv-item {
  padding: 0.1em!important;
}

.cs-notes-button {
  background-color: #0B7DBB!important;
}
