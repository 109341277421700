.cs-school-header-cover {
    background-size: cover;
    background-position: center;
    max-height: 200px;
    width: 100%;
    height: 300px;
    overflow: hidden;
}

.cs-school-header-section {
    background: white;
    margin-right: 0!important;
    margin-left: 0!important;
}

.cs-school-header-logo-actions {
    padding-bottom: .5em!important;
}

.cs-school-header-logo {
    margin-top: -1.5em!important;
}

.cs-school-header-cover-actions {
    padding-right: 1em!important;
    padding-top: .25em!important;
}

.cs-school-header-avatar {
    width: 5em;
    height: 5em;
    border-radius: 2.5em;
    background-size: contain;
    background-repeat: no-repeat;
    margin-top: 1em;
}

.cs-school-header-delete a {
    color: #f5504e!important;
}

.cs-school-header-link a {
    color: #0B7DBB!important;
}

.cs-school-header-disabled a {
    color: #6a7785!important;
}
