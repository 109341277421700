.cs-school-gallery-edit-field-right {
    margin-right: 1.5em;
    margin-top: 0.5em;
    text-align: right;
    margin-bottom: .5em;
}

.cs-school-gallery-footer-actions {
    margin-left: .5em!important;
}

.cs-school-gallery-footer-note {
    padding-top: 1em!important;
    padding-right: 1em!important;
}

.cs-school-gallery-footer {
    background: white;
    margin-right: 0!important;
    margin-left: 0!important;
    border-top-color: #ccc;
    border-top-width: thin;
    border-top-style: solid;
}

.cs-school-gallery-information-icon-item {
    height: 1.5em!important;
    margin-right: .3em!important;
    margin-top: -.25em!important;
    margin-bottom: -.25em!important;
    margin-left: .5em!important;
}

.cs-school-gallery-card {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}

.cs-school-gallery-section {
    background: white;
    margin-right: 0!important;
    margin-left: 0!important;
    padding-bottom: 1em;
}
