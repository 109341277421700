.cs-users-icon {
    height: 1.5em!important;
    margin-right: .3em!important;
    margin-top: -.25em!important;
    margin-bottom: -.25em!important;
    margin-left: .5em!important;
}

.cs-users-footer {
    background: white;
    margin-right: 0!important;
    margin-left: 0!important;
    border-top-color: #ccc;
    border-top-width: thin;
    border-top-style: solid;
}

.cs-users-main {
    padding-top: 3em;
}

.cs-users-title p {
    color: #0FBEDB!important;
    margin-bottom: .5em!important;
}

.cs-users-right {
    margin-bottom: 1em!important;
    height: calc(100vh - 8.5em)!important;
    overflow-y: auto!important;
}

.cs-users-section {
    background: white;
    margin-right: 0!important;
    margin-left: 0!important;
}

.cs-users-section .mbsc-ios.mbsc-select-filter-cont {
    background: white;
}

.cs-users-nav {
    background: #0FBEDB!important;
    margin-left: 0!important;
    margin-right: 0!important;
}

.cs-users-left {
    padding-right: 0!important;
}

.cs-users-container {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 1em;
    height: 4.5em;
}

.cs-users-container-xs {
    padding-left: 0;
    padding-top: .5em;
}
