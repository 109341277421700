.cs-login-grid {
  margin-top: 1em;
  margin-bottom: 1em;
}

.cs-login-grid-small {
  padding: 0!important;
  margin-top: 1em;
  margin-bottom: 1em;
}

.cs-login-main {
  background: white;
  margin-right: 0;
  margin-left: 0;
}

.cs-login-block {
  background: white;
}

.cs-login-button {
  background: #0B7DBB!important;
  color: white!important;
}

.cs-login-button-secondary {
  margin: 0!important;
  color: #0FBEDB!important;
}

.cs-login-button-secondary-icon {
  height: 1.5em!important;
  color: #0FBEDB;
  padding-right: 0.5em;
}

.cs-login-col-forgot {
  margin: 0!important;
}

.cs-login-button-forgot {
  padding-left: 0!important;
}

.cs-login-card-links {
  color: #0FBEDB!important;
  font-weight: 600!important;
}

.cs-login-title h2 {
  padding-top: 1em;
  padding-bottom: 1em;
  color: #f1b254;
}

.cs-login-image {
  background-image: url("../images/LoginBack.png");
  min-height: 30em;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left;
  margin: 0;
}

.cs-login-logo {
  background-image: url("../images/LogoFull.png");
  height: 5em;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left;
  margin: 25px;
}
