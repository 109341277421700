.cs-shared-candidate-main {
    background: white;
    margin-right: 0!important;
    margin-left: 0!important;
}

.cs-shared-candidate-footer {
    background: white;
    margin-right: 0!important;
    margin-left: 0!important;
    border-top-color: #ccc;
    border-top-width: thin;
    border-top-style: solid;
    padding-left: .5em;
}

.cs-shared-candidate-col {
    padding: 0;
}

.cs-shared-candidate-checkbox {
    padding-left: 3.75em!important;
    color: #0B7DBB!important;
    background: white!important;
    margin-top: 1.5em!important;
    margin-right: 1em!important;
    margin-left: 1em!important;
    border-radius: .5em!important;
    border: 1px solid #ccc;
}

.cs-shared-candidate-checkbox:before {
    border-top: 0 solid white!important;
    border-bottom: 0 solid white!important;
}

.cs-shared-candidate-checkbox:after {
    border-bottom: 0 solid white!important;
    border-top: 0 solid white!important;
}

.cs-shared-candidate-nav {
    background: #0FBEDB!important;
    margin-left: 0!important;
    margin-right: 0!important;
}

.cs-shared-candidate-header-title {
    margin-bottom: 0!important;
}

.cs-shared-candidate-avatar {
    width: 2.5em;
    height: 2.5em;
    border-radius: 1.25em;
    background-size: contain;
    background-repeat: no-repeat;
    margin-top: 1em;
    margin-left: 2em;
    margin-right: 2em;
    margin-bottom: 1em;
}

.cs-shared-candidate-list {
    margin-top: .5em;
    margin-left: 1em;
    margin-right: 0;
}

.cs-shared-candidate-icon-referral {
    height: 2.5em!important;
    margin-right: 1em!important;
    margin-top: 0!important;
    margin-bottom: 0!important;
    margin-left: .25em!important;
    color: #376e9f!important;
}

.cs-shared-candidate-file {
    margin-left: 1em;
}

.cs-shared-candidate-file-text {
    margin-top: 0!important;
    margin-bottom: .25em!important;
    margin-left: .75em!important;
}

.cs-shared-candidate-file-button {
    margin-right: .5em;
    margin-left: 0;
}

.cs-shared-candidate-icon-upload {
    height: 1.5em!important;
    margin-right: .3em!important;
    margin-top: -.25em!important;
    margin-bottom: -.25em!important;
    margin-left: .5em!important;
}

.cs-shared-candidate-col-ck {
    background: white;
}

.cs-shared-candidate-col-ck .ck.ck-editor__top .ck-sticky-panel .ck-sticky-panel__content {
    border: none!important;
    display: none!important;
}

.cs-shared-candidate-name {
    margin-top: .5em;
    margin-left: 1em;
}

.cs-shared-candidate-detail {
    margin-right: 2.5em;
}

.cs-shared-candidate-button-icon {
    height: 1.25em!important;
    color: #0B7DBB!important;
    margin-right: .5em!important;
}
