.cs-login-renew-popup .mbsc-popup-padding {
    padding: 0;
}

.cs-login-renew-helper {
    margin-right: 1em;
    margin-left: 1em;
}

.cs-login-renew-cancel {
    color: white!important;
    background: #0B7DBB!important;
    font-weight: bold;
}

.cs-login-renew-save {
    color: white!important;
    background: #0B7DBB!important;
    font-weight: bold;
}

.cs-login-renew-group-title-text {
    margin-bottom: 0.25em!important;
}
