.cs-shared-job-header {
    padding-left: 2.5em;
    padding-top: 1.5em;
}

.cs-shared-job-main {
    background: white;
    margin-right: 0!important;
    margin-left: 0!important;
}

.cs-shared-job-nav {
    background: #0FBEDB!important;
    margin-left: 0!important;
    margin-right: 0!important;
}

.cs-shared-job-footer {
    background: white;
    margin-right: 0!important;
    margin-left: 0!important;
    border-top-color: #ccc;
    border-top-width: thin;
    border-top-style: solid;
}

.cs-shared-job-footer-actions {
    margin-left: .5em!important;
}

.cs-shared-job-col {
    padding: 0;
}

.cs-shared-job-col-ck {
    background: white;
}

.cs-shared-job-col-ck .ck.ck-editor__top .ck-sticky-panel .ck-sticky-panel__content {
    border: none!important;
    display: none!important;
}

.cs-shared-job-header-title {
    margin-bottom: 0!important;
}

.cs-shared-job-avatar {
    width: 2.5em;
    height: 2.5em;
    border-radius: 1.25em;
    background-size: contain;
    background-repeat: no-repeat;
    margin-top: 1em;
    margin-left: 2em;
    margin-right: 1.5em;
    margin-bottom: 1em;
}

.cs-shared-job-links {
    color: #0FBEDB!important;
    font-weight: 600!important;
    cursor: pointer;
}

.cs-shared-job-button-icon {
    height: 1.25em!important;
    color: #0B7DBB!important;
    margin-right: .5em!important;
}

.cs-shared-job-footer-note {
    padding-top: 1em!important;
    padding-right: 1em!important;
}

.cs-shared-job-helper {
    margin-right: 2em;
    margin-left: 1em;
    margin-top: 1.5em;
}
