.cs-schools-main {
    padding-top: 3em;
}

.cs-schools-title p {
    color: #0FBEDB!important;
    margin-bottom: .5em!important;
}

.cs-schools-right {
    margin-bottom: 1em!important;
    height: calc(100vh - 8.5em)!important;
    overflow-y: auto!important;
}

.cs-schools-left {
    padding-right: 0!important;
}

.cs-schools-container {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 1em;
    height: 4.5em;
}

.cs-schools-container-xs {
    padding-left: 0;
    padding-top: .5em;
}

.cs-schools-nav {
    background: #0FBEDB!important;
    margin-left: 0!important;
    margin-right: 0!important;
}

.cs-schools-section {
    background: white;
    margin-right: 0!important;
    margin-left: 0!important;
}
