.cs-candidates-favorites-results-list {
    margin-top: 3px;
    padding-left: 1.5em;
    margin-right: 0;
    padding-top: 3px;
}

.cs-candidates-favorites-results-avatar {
    width: 2.5em;
    height: 2.5em;
    border-radius: 1.25em;
    background-size: contain;
    background-repeat: no-repeat;
    margin-top: .25em;
    margin-left: 0;
    margin-right: .5em;
}

.cs-candidates-favorites-results-file {
    margin-left: 1em;
}

.cs-candidates-favorites-job-text {
    margin-top: 0!important;
    margin-bottom: .25em!important;
    margin-left: .75em!important;
}

.cs-candidates-favorites-note {
    padding-bottom: .30em!important;
    padding-top: .30em!important;
    padding-left: .50em!important;
    padding-right: .50em!important;
    margin-top: 0!important;
    margin-bottom: 0!important;
    margin-left: .50em!important;
    margin-right: 0!important;
}

.cs-candidates-favorites-icon {
    height: 1.25em!important;
    color: #0B7DBB!important;
    margin-left: 1em!important;
}

.cs-candidates-favorites-file-button {
    margin-right: .5em;
    margin-left: 0;
}

.cs-candidates-favorites-icon-upload {
    height: 1.5em!important;
    margin-right: .3em!important;
    margin-top: -.25em!important;
    margin-bottom: -.25em!important;
    margin-left: .5em!important;
}

.cs-candidates-favorites-helper {
    margin-right: 2em!important;
    margin-left: 1em!important;
    margin-top: 1.5em;
}
