.cs-users-new-popup .mbsc-popup-padding {
    padding: 0;
}

.cs-users-new-save {
    color: white!important;
    background: #0B7DBB!important;
    font-weight: bold;
}

.cs-users-new-cancel {
    color: white!important;
    background: #0B7DBB!important;
    font-weight: bold;
}
