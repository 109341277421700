.cs-user-job-main {
    background: white;
    margin-right: 0!important;
    margin-left: 0!important;
}

.cs-user-job-helper {
    margin-right: 1em;
    margin-left: 1em;
}

.cs-user-job-error {
    margin-bottom: 0!important;
}

.cs-user-job-badge-incomplete {
    height: 2em!important;
    color: #f5504e!important;
    margin-top: .75em;
    margin-right: .75em;
    margin-left: 1em;
}

.cs-user-job-footer {
    background: white;
    margin-right: 0!important;
    margin-left: 0!important;
    border-top-color: #ccc;
    border-top-width: thin;
    border-top-style: solid;
}

.cs-user-job-footer-actions {
    margin-left: .5em!important;
}

.cs-user-job-footer-note {
    padding-top: 1em!important;
    padding-bottom: 1em!important;
    padding-right: 1em!important;
}

.cs-user-job-checkbox {
    padding-left: 3.75em!important;
    color: #0B7DBB!important;
    background: white!important;
    margin-top: 1.5em!important;
    margin-right: 1em!important;
    margin-left: 1em!important;
    border-radius: .5em!important;
    border: 1px solid #ccc;
}

.cs-user-job-checkbox:before {
    border-top: 0 solid white!important;
    border-bottom: 0 solid white!important;
}

.cs-user-job-checkbox:after {
    border-bottom: 0 solid white!important;
    border-top: 0 solid white!important;
}
