.cs-job-job-main {
    background: white;
    margin-right: 0!important;
    margin-left: 0!important;
}

.cs-job-job-checkbox {
    padding-left: 3.75em!important;
    color: #0B7DBB!important;
    background: white!important;
    margin-top: 1.5em!important;
    margin-right: 1em!important;
    margin-left: 1em!important;
    border-radius: .5em!important;
    border: 1px solid #ccc;
}

.cs-job-job-checkbox:before {
    border-top: 0 solid white!important;
    border-bottom: 0 solid white!important;
}

.cs-job-job-checkbox:after {
    border-bottom: 0 solid white!important;
    border-top: 0 solid white!important;
}

.cs-job-job-footer {
    background: white;
    margin-right: 0!important;
    margin-left: 0!important;
    border-top-color: #ccc;
    border-top-width: thin;
    border-top-style: solid;
    padding-left: 1em;
    padding-right: 1em;
}

.cs-job-job-icon-upload {
    height: 1.5em!important;
    margin-right: .3em!important;
    margin-top: -.25em!important;
    margin-bottom: -.25em!important;
    margin-left: .5em!important;
}
