.cs-shared-school-text {
    text-align: center;
}

.cs-shared-school-text-p {
    margin: 0!important;
}

.cs-shared-school-header-title {
    margin-bottom: 0!important;
}

.cs-shared-school-cover {
    background-size: cover;
    background-position: center;
    max-height: 300px;
    width: 100%;
    height: 300px;
    overflow: hidden;
    margin-right: 0!important;
    margin-left: 0!important;
}

.cs-shared-school-main {
    background: white;
    margin-right: 0!important;
    margin-left: 0!important;
}

.cs-shared-school-col-ck {
    background: white;
}

.cs-shared-school-col-ck .ck.ck-editor__top .ck-sticky-panel .ck-sticky-panel__content {
    border: none!important;
    display: none!important;
}

.cs-shared-school-gallery {
    background: white;
    margin-right: 0!important;
    margin-left: 0!important;
    margin-bottom: 1em;
    padding-bottom: 1em;
}

.cs-shared-school-card-gallery {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}

.cs-shared-school-footer {
    background: white;
    margin-right: 0!important;
    margin-left: 0!important;
    border-top-color: #ccc;
    border-top-width: thin;
    border-top-style: solid;
    padding-left: .5em;
}

.cs-shared-school-card {
    background: #efeff4 !important;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}

.cs-shared-school-card-empty {
    background: #efeff4 !important;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
    padding-top: 1.6em;
    padding-bottom: 1.8em;
}

.cs-shared-school-card-section-left {
    padding-right: 0.5em!important;
}

.cs-shared-school-card-section-middle {
    padding-right: 0.5em!important;
    padding-left: 0.5em!important;
}

.cs-shared-school-card-section-right {
    padding-left: 0.5em!important;
}

.cs-shared-school-card-section {
    margin-bottom: 1em;
}

.cs-shared-school-nav {
    background: #0FBEDB!important;
    margin-left: 0!important;
    margin-right: 0!important;
}

.cs-shared-school-avatar {
    width: 2.5em;
    height: 2.5em;
    border-radius: 1.25em;
    background-size: contain;
    background-repeat: no-repeat;
    margin-top: 1em;
    margin-left: 2em;
    margin-right: 2em;
    margin-bottom: 1em;
}

.cs-shared-school-button-icon {
    height: 1.25em!important;
    color: #0B7DBB!important;
    margin-right: .5em!important;
}

.cs-shared-school-list {
    margin-left: 1.5em;
    margin-right: 0;
}

.cs-shared-school-file-text {
    margin-top: 0!important;
    margin-bottom: .25em!important;
}
